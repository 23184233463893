import { useParams } from 'react-router-dom';

import {
  ATTACHMENTS_PLACEMENTS,
  DOCUMENT_TYPES,
} from '@savgroup-front-common/constants';
import { GetFullClaimContextQueryReturnValues } from '@savgroup-front-common/core/src/api/Claim/getFullClaimContextQuery';
import { DocumentData } from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';
import {
  AdditionalClaimInformation,
  Document,
} from '@savgroup-front-common/types';
import { useGetCarrierLabelsInfosQuery } from 'myaccount/view/app/hooks/useGetCarrierLabelsInfosQuery';

interface UseGetDocumentsArgs {
  fullClaim: GetFullClaimContextQueryReturnValues;
}
interface UseGetDocumentsReturnValue {
  allDocuments: DocumentData[];
}

const useGetDocuments = ({
  fullClaim,
}: UseGetDocumentsArgs): UseGetDocumentsReturnValue => {
  const { fileId } = useParams<{ fileId: string }>();

  const claimAdditionalInformationDocuments: AdditionalClaimInformation[] =
    fullClaim?.additionalInformation
      ?.filter(
        (additionalInformation: AdditionalClaimInformation) =>
          additionalInformation.additionalInformationFileValue &&
          additionalInformation.additionalInformationFileValue
            .customerVisibility,
      )
      .flat() || [];

  const claimAdditionalInformationDocumentsEnhanced =
    claimAdditionalInformationDocuments.map(
      (claimAdditionalInformationDocument) => {
        const { additionalInformationFileValue, additionalInformationLabel } =
          claimAdditionalInformationDocument;
        const attachmentId = additionalInformationFileValue?.url;
        const documentName = additionalInformationLabel
          ? `${additionalInformationLabel} : ${additionalInformationFileValue?.fileName}`
          : additionalInformationFileValue?.fileName || '';

        return {
          ...additionalInformationFileValue,
          fileId,
          attachmentId,
          documentName,
          type: ATTACHMENTS_PLACEMENTS.ADDITIONAL_INFORMATION_DOCUMENTS,
        };
      },
    );

  const { carriersLabels: shippinglabels } =
    useGetCarrierLabelsInfosQuery({ fileId }) || [];

  const shippingLabelsWithType = (shippinglabels || [])
    .filter(
      (doc) => !doc.disabled && doc.customerVisibility && doc.hasLabelGenerated,
    )
    .map((doc) => {
      return {
        ...doc,
        type: DOCUMENT_TYPES.SHIPPING_LABEL,
        documentName: DOCUMENT_TYPES.SHIPPING_LABEL,
      };
    });

  const client =
    fullClaim?.documents?.filter(
      (document: Document) =>
        document.name === DOCUMENT_TYPES.IMAGE ||
        (document.name !== DOCUMENT_TYPES.RETURN_VOUCHER &&
          document.originalFileName &&
          document.customerVisibility),
    ) || [];

  const clientDocument = client.map((doc) => {
    return {
      ...doc,
      type: DOCUMENT_TYPES.IMAGE,
      documentName:
        doc.name === DOCUMENT_TYPES.IMAGE
          ? DOCUMENT_TYPES.IMAGE
          : doc.originalFileName || '',
    };
  });

  const voucher =
    fullClaim?.documents?.filter(
      (document: Document) =>
        document.name !== DOCUMENT_TYPES.IMAGE && document.customerVisibility,
    ) || [];

  const voucherDocument = voucher.reduce(
    (acc: DocumentData[], doc: Document) => {
      return [
        ...acc,
        {
          ...doc,
          type: doc.name,
          documentName: doc.name || '',
          counter:
            acc.filter((d) => d.type === DOCUMENT_TYPES.BILLING).length + 1,
        },
      ];
    },
    [],
  );

  const allDocuments = [
    ...voucherDocument,
    ...shippingLabelsWithType,
    ...clientDocument,
    ...claimAdditionalInformationDocumentsEnhanced,
  ];

  return { allDocuments: allDocuments || [] };
};

export default useGetDocuments;
