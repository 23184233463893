import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import {
  CURRENCIES,
  HANDLING_MODES,
  HANDLING_UNIQUE_NAME,
  media,
} from '@savgroup-front-common/constants';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import { getHandlingData } from '../../../helpers';

import messages from './messages';

interface ResumeDepositProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
  handlingStatus?: HANDLING_MODES;
}

const ResumeDeposit: React.FC<ResumeDepositProps> = ({
  claimGroupConfirmation,
  handlingStatus,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  const addressName = `${
    claimGroupConfirmation?.depositAddress?.firstname || ''
  } ${claimGroupConfirmation?.depositAddress?.lastname || ''}`;
  const address = claimGroupConfirmation?.depositAddress?.address;
  const postalCode = claimGroupConfirmation?.depositAddress?.postalCode;
  const city = claimGroupConfirmation?.depositAddress?.city;
  const addressFormated = `${address} ${postalCode} ${city}`;

  const { label, description } = getHandlingData({
    carrierUniqueName:
      claimGroupConfirmation?.carrierDepositTransportMethodUniqueName as HANDLING_UNIQUE_NAME,
  });

  const prefixTitle =
    handlingStatus === HANDLING_MODES.HOME
      ? messages.depositTitleIsHome
      : messages.depositTitleIsDeposit;

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={prefixTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  ) : (
    <SimpleItemCard
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={prefixTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  );
};

ResumeDeposit.displayName = 'ResumeDeposit';

export default ResumeDeposit;
