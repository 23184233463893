import { useQuery } from 'react-query';

import { ClaimService } from '../../../../api';

export const GetClaimContextHandlingQuery = 'geetClaimContextHandlingQuery';

export const useGetClaimContextHandlingQuery = ({
  claimId,
}: {
  claimId?: string;
}) => {
  const { data: claimContextHandling } = useQuery(
    [GetClaimContextHandlingQuery, { claimId }],
    async () => {
      if (!claimId) {
        return undefined;
      }

      const response = await ClaimService.getClaimContextHandlingQuery({
        claimId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      cacheTime: Infinity,
      refetchIntervalInBackground: true,
      refetchInterval: 3000,
    },
  );

  return {
    claimContextHandling,
  };
};
