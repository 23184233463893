/* eslint-disable max-len */

export const REGEX_RULES = {
  ORDER_REFERENCE: /^[a-zA-Z0-9\-_]+$/,
  USER_PROFILE: /^[A-Za-z0-9 _-]*$/,
  CITY_NAME: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  EMAIL:
    /^(([^áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ<>()[\]\\.,;:\s@"]+(\.[^áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
  PRICE: /^[0-9]*([.,][0-9]{0,2})?$/,
  PRICE_WITH_SPACE: /^[\s0-9]*([.,][0-9]{0,2})?$/,
  NEGATIVE_PRICE: /^-{1}[0-9]*([.,][0-9]{0,2})?$/,
  FRACTIONAL_PRICE: /^[0-9]*([.,][0-9]*)?$/,
  IMEI: /^[0-9]{15}$/,
  PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s0-9 ]*$/,
  URL: /^(https:\/\/|http:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  URL_WITH_PROTOCOL:
    /^(https:\/\/|http:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  ADDRESS: /([0-9].+)|(.+[0-9])/,
  NUMBER: /^[0-9]*$/,
  NOT_ONLY_NUMBER: /^(?!^\d+$)^.+$/,
};
