import size from 'lodash/size';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import { EVENT_KEY } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import { useIsNewUiEnabled } from '../../../hooks/useIsNewUiEnabled';
import useScrollIntoView from '../../../hooks/useScroll';
import { $Container, Wrapper } from '../common';
import FieldLoader from '../common/FieldLoader';
import FieldMessage from '../common/FieldMessage';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { FieldMessages } from '../common/helpers/getFinalFieldState.types';
import { Label } from '../common/Label/Label';

import messages from './messages';
import { updateHeight } from './Textarea.helpers';
import {
  $CustomIcon,
  $IconContainer,
  $RemainingCharactersStyled,
  $StyledTextArea,
  $SuffixBottomButton,
  $TextareaContainer,
  $TextAreaFooter,
} from './Textarea.styles';

export interface TextAreaComponentProps {
  children?: ReactNode;

  onClick?: MouseEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onMouseEnter?: MouseEventHandler<HTMLTextAreaElement>;
  onMouseLeave?: MouseEventHandler<HTMLTextAreaElement>;
  onEnterKeyPressed?: KeyboardEventHandler<HTMLTextAreaElement>;

  placeholder?: string | MessageType;

  dataTestId?: string;
  componentThemeName?: string;
  value?: string;
  defaultValue?: string;
  name: string;
  suffix?: string | MessageType;
  prefix?: string | MessageType;
  label?: string | MessageType;

  isSuccess?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  errors?: FieldMessages;
  successes?: FieldMessages;
  warnings?: FieldMessages;

  autoScroll?: boolean;
  isRequired?: boolean;
  autoHeight?: boolean;
  isInitialOneLine?: boolean;

  maxLength?: number;
  postLabel?: string | ReactElement;
  icon?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  isScrollIntoView?: boolean;
  internalId?: string;
  suffixOptionButton?: React.ComponentType<any>;
}

interface TextAreaProps extends TextAreaComponentProps {
  forwardedRef: Ref<HTMLTextAreaElement>;
}

const Textarea: FunctionComponent<React.PropsWithChildren<TextAreaProps>> = ({
  name,
  label,
  value,
  defaultValue,
  icon,
  isError = false,
  isSuccess = false,
  isWarning = false,
  isLoading = false,
  isRequired = false,
  forwardedRef,
  autoHeight = false,
  onChange,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  autoScroll = true,
  maxLength = 0,
  postLabel,
  isDisabled = false,
  errors = {},
  successes = {},
  warnings = {},
  dataTestId,
  placeholder,
  isInitialOneLine = false,
  onEnterKeyPressed = () => undefined,
  suffix = '',
  prefix = '',
  isScrollIntoView = false,
  internalId,
  suffixOptionButton,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const theme = useTheme();
  const { scrollIntoView } = useScrollIntoView({
    ref: textAreaRef,
    isScrollIntoView,
  });
  const isNewUiEnabled = useIsNewUiEnabled();

  const combinedRefs = useCombinedRefs(forwardedRef, textAreaRef);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const timoutId = useRef<any | null>();

  const [valueCount, setValueCount] = useState(size(value) || 0);

  const { height, maxHeight } = textAreaRef.current
    ? window.getComputedStyle(textAreaRef.current)
    : { height: '0px', maxHeight: '100px' };

  useEffect(() => {
    updateHeight(valueCount, textAreaRef.current, autoScroll);

    return () => {
      if (timoutId.current !== null) {
        clearTimeout(timoutId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValueCount(event.target.value.length);
    if (onChange && typeof onChange === 'function') {
      onChange(event);
    }
    if (autoHeight) {
      if (timoutId.current !== null) {
        clearTimeout(timoutId.current);
        timoutId.current = null;
      }

      const innerId = window.setTimeout(() => {
        updateHeight(
          event.target.value.length,
          textAreaRef.current,
          autoScroll,
        );
        timoutId.current = null;
      }, 100);

      timoutId.current = innerId;
    }
  };

  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$Container>
      {label && (
        <Label
          htmlFor={name}
          isRequired={isRequired}
          status={status}
          postLabel={postLabel}
        >
          <SafeFormattedMessageWithoutSpread
            message={label}
            values={typeof label !== 'string' ? label.values : undefined}
          />
        </Label>
      )}
      <Wrapper
        name={name}
        status={status}
        prefix={prefix}
        suffix={suffix}
        isFocused={isFocused}
        isHovered={isHovered}
      >
        <$TextareaContainer>
          {icon && typeof icon !== 'string' && (
            <$IconContainer>
              <$CustomIcon
                icon={icon}
                color={theme.colors.mainTextColor}
                size="20px"
              />
            </$IconContainer>
          )}
          <$StyledTextArea
            $isScrollBarActive={
              valueCount > 0 &&
              Number(height.replace('px', '')) >=
                Number(maxHeight.replace('px', ''))
            }
            data-testid={dataTestId}
            $isNewUiEnabled={isNewUiEnabled}
            ref={combinedRefs}
            {...rest}
            $hasIcon={Boolean(icon)}
            $isLoading={isLoading}
            $status={status}
            $isRequired={isRequired}
            onChange={handleChange}
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }
              setIsFocused(true);
              if (isScrollIntoView) {
                scrollIntoView();
              }
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              setIsFocused(false);
            }}
            onMouseEnter={(e) => {
              if (onMouseEnter) {
                onMouseEnter(e);
              }
              setIsHovered(true);
            }}
            onMouseLeave={(e) => {
              if (onMouseLeave) {
                onMouseLeave(e);
              }
              setIsHovered(false);
            }}
            onKeyPress={(event) => {
              if (event.key === EVENT_KEY.ENTER && !event.shiftKey) {
                onEnterKeyPressed(event);
              }
            }}
            id={name}
            name={name}
            value={value}
            defaultValue={defaultValue}
            disabled={isDisabled}
            placeholder={safeFormattedIntlString(placeholder)}
            $isInitialOneLine={isInitialOneLine}
          />
          {isLoading && <FieldLoader name={name} />}
        </$TextareaContainer>
      </Wrapper>
      {maxLength > 0 && (
        <$TextAreaFooter>
          <$RemainingCharactersStyled>
            <Label>
              <SafeFormattedMessageWithoutSpread
                message={messages.remainingCharacters}
                values={{ length: maxLength - valueCount }}
              />
            </Label>
          </$RemainingCharactersStyled>
        </$TextAreaFooter>
      )}
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
      {suffixOptionButton && (
        <$SuffixBottomButton>
          {React.createElement(suffixOptionButton, {
            value: combinedRefs.current?.value,
            id: internalId,
          })}
        </$SuffixBottomButton>
      )}
    </$Container>
  );
};

Textarea.displayName = 'Textarea';

export default React.forwardRef<HTMLTextAreaElement, TextAreaComponentProps>(
  (props, ref) => <Textarea forwardedRef={ref} {...props} />,
);
