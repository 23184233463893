import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  PAYMENT_BEHAVIOUR,
  REVALIDATE_MODES,
  HANDLING_MODES,
  SPECIFIC_ERROR_CODES,
} from '@savgroup-front-common/constants';
import { formatPhoneNumber } from '@savgroup-front-common/core/src/formatters';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';
import useGetOrigin from 'myaccount/view/app/hooks/useGetOrigin';
import { useCreateInvoiceWithClaimGroup } from 'myaccount/view/app/hooks/useCreateInvoiceWithClaimGroup';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';
import { buildFileFollowupUrl } from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';
import {
  StepContext,
  StepContextValues,
} from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import claimGroupConfirmationSchema from './ClaimGroupConfirmationPage.schema';
import { ClaimGroupConfirmationValues } from './ClaimGroupConfirmationPage.types';

const useClaimGroupConfirmationPage = () => {
  const query = useSearchParams();
  const history = useHistory();
  const { origin } = useGetOrigin();
  const paymentConfirmed = query.has('paymentConfirmed');
  const { removeAllNotifications, pushErrors } = useToasts();
  const { login } = useLayout();
  const { values, handleValidateStep } =
    useContext<StepContextValues>(StepContext);
  const claimGroupId = values?.claimGroup?.claimGroupId;
  const { claimGroupConfirmation, isLoading: isGetConfirmationLoading } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });
  const sellerId = values.orders?.at(0)?.sellerId;
  const { sellerConfiguration, isLoading: isGetSellerConfigurationLoading } =
    useGetSellerConfiguration({ sellerId });

  const { errors, isLoading: isCheckInvoiceLoading } =
    useCreateInvoiceWithClaimGroup({ claimGroupId });
  const isInvoiceAlreadyPaid = errors?.some(
    (error) => error.code === SPECIFIC_ERROR_CODES.INVOICE_ALREADY_PAID,
  );

  const agreementUrl =
    sellerConfiguration?.cgvUrl ||
    `${APIConfiguration.catalogCDN}miscellaneous/CGU%20Revers.io%20-%20Client%20final_fr.pdf`;

  const paymentRequired =
    !isInvoiceAlreadyPaid &&
    claimGroupConfirmation?.paymentBehavior ===
      PAYMENT_BEHAVIOUR.PAYMENT_REQUIRED;

  const formContext = useForm<ClaimGroupConfirmationValues>({
    resolver: yupResolver(claimGroupConfirmationSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      phone:
        formatPhoneNumber(
          login.phone || login?.ownerAddress?.phone,
          login.countryCode,
        ) || '',
      email: login.email,
      cgv: paymentConfirmed,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formContext;

  const {
    mutateAsync: handleConfirmationSubmit,
    isLoading: isLoadingConformationSubmit,
  } = useMutation(
    ['setConfirmationSubmit'],
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        phoneNumber?: string;
        mail?: string;
        swornStatement?: boolean;
        generalSalesCondition?: boolean;
        iMEI?: string;
        devicePassword?: string;
        serialNumber?: string;
        invoiceId?: string;
        sourceTokenId?: string;
        issueDate?: Date;
        origin?: string;
        ticketId?: string;
      };
    }) => {
      removeAllNotifications();
      if (!claimGroupId) {
        return undefined;
      }

      const responseConfirmation = await ClaimService.setClaimConfirmation({
        claimGroupId,
        payload,
      });

      if (responseConfirmation.failure) {
        pushErrors(responseConfirmation.errors);

        return undefined;
      }

      return responseConfirmation;
    },
  );

  const onSubmit = handleSubmit(async ({ email, phone }) => {
    if (claimGroupId) {
      const payload = {
        phoneNumber: phone,
        mail: email,
        swornStatement: undefined,
        generalSalesCondition: true,
        iMEI: '',
        serialNumber: '',
        devicePassword: '',
        invoiceId: undefined,
        sourceTokenId: '',
        issueDate: new Date(),
        origin,
        ticketId: undefined,
      };

      const responseSetConfirmation = await handleConfirmationSubmit({
        claimGroupId,
        payload,
      });

      if (!responseSetConfirmation) {
        return undefined;
      }

      const responseGetConfirmation =
        await ClaimService.getClaimGroupConfirmationQuery({
          claimGroupId,
        });

      if (responseGetConfirmation.failure) {
        return undefined;
      }
      const { fileId } = <ClaimGroupConfirmation>responseGetConfirmation.value;

      if (fileId) {
        const finalUrl = buildFileFollowupUrl({
          fileId,
        });

        history.push(finalUrl);
      }

      return undefined;
    }

    return undefined;
  });

  useEffect(() => {
    handleValidateStep(isValid);
  }, [handleValidateStep, isValid]);

  useEffect(() => {
    if (paymentConfirmed) {
      onSubmit();
    }
  }, [paymentConfirmed, onSubmit]);

  const isLoading =
    isCheckInvoiceLoading ||
    isGetConfirmationLoading ||
    isGetSellerConfigurationLoading;

  return {
    claimGroupConfirmation,
    claimGroupId,
    formContext,
    onSubmit,
    agreementUrl,
    hasDeposit: values?.handlingStatus?.hasDeposit || values?.handlingStatus?.hasHome,
    hasDelivery: values?.handlingStatus?.hasDelivery,
    isInvoiceAlreadyPaid,
    isLoading,
    paymentRequired,
    sellerId,
    isLoadingSubmit: isLoadingConformationSubmit,
    isCheckInvoiceLoading,
    hasDepositStatus: values?.handlingStatus?.hasHome
      ? HANDLING_MODES.HOME
      : HANDLING_MODES.DEPOSIT,
  };
};

export default useClaimGroupConfirmationPage;
