import get from 'lodash/get';
import map from 'lodash/map';
import { createSelector } from 'reselect';

import { LANGUAGES } from '@savgroup-front-common/constants/src/shared';
import { BaseAutocompleteOptionForHookForm } from '@savgroup-front-common/types';

import deCountryList from './country-list/country-de.json';
import enCountryList from './country-list/country-en.json';
import esCountryList from './country-list/country-es.json';
import frCountryList from './country-list/country-fr.json';
import itCountryList from './country-list/country-it.json';
import nlCountryList from './country-list/country-nl.json';
import ptCountryList from './country-list/country-pt.json';
import deLanguageList from './language-list/language-de.json';
import enLanguageList from './language-list/language-en.json';
import esLanguageList from './language-list/language-es.json';
import frLanguageList from './language-list/language-fr.json';
import itLanguageList from './language-list/language-it.json';
import nativeLanguages from './language-list/language-native.json';
import nlLanguageList from './language-list/language-nl.json';
import ptLanguageList from './language-list/language-pt.json';
import { I18nAppState } from './types';

export const selectLocale = (state: { i18n: I18nAppState }): string =>
  state.i18n;

function formatCountryData(
  data: Record<string, string>,
): BaseAutocompleteOptionForHookForm[] {
  return map(data, (countryName, countryCode) => ({
    label: countryName,
    value: countryCode,
  }));
}

enum LIST_KEY {
  COUNTRY_AUTOCOMPLETE_OPTION_LIST = 'countryList',
  COUNTRY_MAP = 'countryMap',
  LANGUAGE_MAP = 'languageMap',
}

export const list = {
  [LIST_KEY.COUNTRY_AUTOCOMPLETE_OPTION_LIST]: {
    [LANGUAGES.FR as string]: formatCountryData(frCountryList),
    [LANGUAGES.EN as string]: formatCountryData(enCountryList),
    [LANGUAGES.DE as string]: formatCountryData(deCountryList),
    [LANGUAGES.NL as string]: formatCountryData(nlCountryList),
    [LANGUAGES.ES as string]: formatCountryData(esCountryList),
    [LANGUAGES.PT as string]: formatCountryData(ptCountryList),
    [LANGUAGES.IT as string]: formatCountryData(itCountryList),
  },
  [LIST_KEY.COUNTRY_MAP]: {
    [LANGUAGES.FR as string]: frCountryList,
    [LANGUAGES.EN as string]: enCountryList,
    [LANGUAGES.DE as string]: deCountryList,
    [LANGUAGES.NL as string]: nlCountryList,
    [LANGUAGES.ES as string]: esCountryList,
    [LANGUAGES.PT as string]: ptCountryList,
    [LANGUAGES.IT as string]: itCountryList,
  },
  [LIST_KEY.LANGUAGE_MAP]: {
    [LANGUAGES.FR as string]: frLanguageList,
    [LANGUAGES.EN as string]: enLanguageList,
    [LANGUAGES.DE as string]: deLanguageList,
    [LANGUAGES.NL as string]: nlLanguageList,
    [LANGUAGES.ES as string]: esLanguageList,
    [LANGUAGES.PT as string]: ptLanguageList,
    [LANGUAGES.IT as string]: itLanguageList,
  },
};

export const countryList = createSelector(
  [selectLocale],
  (locale): BaseAutocompleteOptionForHookForm[] => {
    return list[LIST_KEY.COUNTRY_AUTOCOMPLETE_OPTION_LIST]?.[locale] || [];
  },
);

export const countryMap = createSelector([selectLocale], (locale) => {
  return list[LIST_KEY.COUNTRY_MAP]?.[locale] || {};
});

export const selectLanguageMap = createSelector([selectLocale], (locale) => {
  return list[LIST_KEY.LANGUAGE_MAP]?.[locale] || {};
});

export const getCountryTranslationSelector = createSelector(
  [countryMap],
  (countries) =>
    (countryCode: string): string => {
      if (typeof countryCode !== 'string') {
        return '';
      }

      return get(countries, countryCode.toUpperCase(), '');
    },
);

export const getLanguageTranslationSelector = createSelector(
  [selectLanguageMap],
  (languages) => (languageCode: string) => {
    if (typeof languageCode !== 'string') {
      return null;
    }

    return get(languages, languageCode.toLowerCase(), null);
  },
);

export type GetNativeLanguageSelectorArgs =
  | 'en'
  | 'de'
  | 'fr'
  | 'es'
  | 'cs'
  | 'da'
  | 'et'
  | 'fi'
  | 'hr'
  | 'hu'
  | 'it'
  | 'lt'
  | 'lv'
  | 'nl'
  | 'nb'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'sq'
  | 'sv';

export const getNativeLanguageSelector =
  () =>
  (languageCode: GetNativeLanguageSelectorArgs): string | null => {
    if (typeof languageCode !== 'string' || !languageCode) {
      return null;
    }

    return (
      nativeLanguages?.[
        languageCode.toLowerCase() as GetNativeLanguageSelectorArgs
      ] || null
    );
  };
