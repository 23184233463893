import React, { FunctionComponent, ReactNode } from 'react';
import { Tooltip, TooltipProps } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

interface CustomTooltipProps extends TooltipProps {
  children: ReactNode;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = (props) => (
  <Tooltip {...props} />
);

export default CustomTooltip;
