import React, { FunctionComponent } from 'react';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $Tag } from './Tag.styles';

interface TagProps {
  noEllispis?: boolean;
  disabled?: boolean;
  danger?: boolean;
  neutral?: boolean;
  alert?: boolean;
  success?: boolean;
  primary?: boolean;
  secondary?: boolean;
  withoutMargin?: boolean;
  fluid?: boolean;
  dataTestId?: string;
  isNaked?: boolean;
  isSmall?: boolean;
  isCapitalized?: boolean;
  onClick?: () => void;
}

const Tag: FunctionComponent<React.PropsWithChildren<TagProps>> = ({
  children,
  disabled = false,
  danger = false,
  alert = false,
  success = false,
  primary = false,
  secondary = false,
  neutral = false,
  withoutMargin = false,
  fluid = false,
  dataTestId = 'tag',
  noEllispis = false,
  isNaked = false,
  isSmall = false,
  isCapitalized = false,
  onClick,
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$Tag
      $isCapitalized={isCapitalized}
      disabled={disabled}
      neutral={neutral}
      danger={danger}
      alert={alert}
      success={success}
      primary={primary}
      secondary={secondary}
      withoutMargin={withoutMargin}
      data-testid={dataTestId}
      $noEllispis={noEllispis}
      $fluid={fluid}
      $isNaked={isNaked}
      $isSmall={isSmall}
      $isNewUiEnabled={isNewUiEnabled}
      onClick={onClick}
    >
      {children}
    </$Tag>
  );
};

export default Tag;
