import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetFullClaimContextQuery = 'GetFullClaimContextQuery';

export const useGetFullClaimContextQuery = ({
  claimId,
}: {
  claimId?: string;
}) => {
  const { data: fullClaim, isLoading } = useQuery(
    [GetFullClaimContextQuery, { claimId }],
    async () => {
      if (!claimId) {
        return undefined;
      }

      const response = await ClaimService.getFullClaimContextQuery({
        claimId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: 3000,
    },
  );

  return {
    fullClaim,
    isLoading,
  };
};
