import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import React, { FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

import { INPUT_TYPES } from '@savgroup-front-common/constants';

import { SafeFormattedMessage } from '../../../formatters';
import { safeFormattedIntlString } from '../../../formatters/safeFormattedIntlString';
import { CalendarIcon } from '../../../protons/icons/Calendar.icon';
import { Label } from '../common';
import $Container from '../common/Container/Container.styles';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import Input from '../Input/Input';

import { $Wrapper } from './Datepicker.styles';
import { DatePickerProps, LegacyDatePickerPropsRef } from './Datepicker.types';
import messages from './messages';

const dateFormat = 'dd/MM/yyyy';

registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('nl', nl);
registerLocale('pt', pt);
registerLocale('it', it);

enum DROPDOWN_MODE {
  SCROLL = 'scroll',
  SELECT = 'select',
}

const Datepicker: FC<LegacyDatePickerPropsRef> = ({
  value,
  disabled = false,
  isDisabled = false,
  isLoading = false,
  onBlur = undefined,
  onFocus = undefined,
  placeholder = messages.placeholder,
  name,
  label,
  postLabel,
  isRequired = false,
  onChange = () => undefined,
  minDate,
  maxDate,
  forwardedRef,
  dataTestId = 'date-picker',

  errors = {},
  warnings = {},
  successes = {},

  isError = false,
  isWarning = false,
  isSuccess = false,

  suffix = '',
  prefix = '',

  selectsStart = false,
  selectsEnd = false,
  startDate,
  endDate,
  selectsRange = false,
  isClearable = true,
  monthsShown = 1,
  showMonthDropdown = true,
  showYearDropdown = true,
  focusSelectedMonth = false,
}) => {
  const intl = useIntl();
  const transitionDisabled = disabled || isDisabled;
  const [status] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$Container>
      <$Wrapper>
        {label && (
          <Label
            htmlFor={name}
            isRequired={isRequired}
            status={status}
            postLabel={postLabel}
            fullWidth={false}
          >
            {SafeFormattedMessage(label)}
          </Label>
        )}

        <DatePicker
          focusSelectedMonth={focusSelectedMonth}
          selected={value}
          onChange={(date: Date | null) => {
            onChange(date ?? undefined);
          }}
          peekNextMonth
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          startDate={startDate}
          endDate={endDate}
          isClearable={isClearable}
          id={name}
          name={name}
          dropdownMode={DROPDOWN_MODE.SELECT}
          minDate={minDate}
          maxDate={maxDate}
          monthsShown={monthsShown}
          locale={intl.locale}
          autoComplete="off"
          ref={forwardedRef}
          customInput={
            <Input
              name={name}
              dataTestId={dataTestId}
              type={INPUT_TYPES.TEXT}
              isError={isError}
              isWarning={isWarning}
              isSuccess={isSuccess}
              errors={errors}
              successes={successes}
              warnings={warnings}
              disableBrowserAutocomplete
              isLoading={isLoading}
              suffix={suffix}
              prefix={prefix}
              data-type="datepicker"
              isDisabled={transitionDisabled}
              icon={<CalendarIcon />}
            />
          }
          dateFormat={dateFormat}
          disabled={transitionDisabled}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholderText={safeFormattedIntlString(placeholder)}
          selectsRange={selectsRange as any}
        />
      </$Wrapper>
    </$Container>
  );
};

Datepicker.displayName = 'Datepicker';

export default React.forwardRef<DatePicker, DatePickerProps>((props, ref) => (
  <Datepicker forwardedRef={ref} {...props} />
));
