import React, { FunctionComponent, useState } from 'react';
import { v4 } from 'uuid';

import { safeFormattedIntlString } from '../../formatters';
import { CopyOnClick } from '../../protons/CopyOnClick/CopyOnClick';
import { notice } from '../Toast';

import { $CopyContainer } from './CopyField.styles';
import messages from './messages';

interface CopyFieldProps {
  copy?: string;
}

const CopyField: FunctionComponent<React.PropsWithChildren<CopyFieldProps>> = ({
  children,
  copy,
}) => {
  const [toastId, setToastId] = useState(() => v4());

  if (copy) {
    return (
      <span title={safeFormattedIntlString(messages.copyToClipboard)}>
        <CopyOnClick<HTMLDivElement>>
          {({ ref, onClick }) => (
            <$CopyContainer
              aria-label="field"
              ref={ref}
              onClick={(e) => {
                onClick(e, copy);
                notice.info(messages.textCopied, {
                  toastId,
                  onClose: () => setToastId(v4()),
                });
              }}
              tabIndex={-1}
              role="button"
            >
              {children}
            </$CopyContainer>
          )}
        </CopyOnClick>
      </span>
    );
  }

  return <>{children}</>;
};

CopyField.displayName = 'CopyField';

export default CopyField;
