const upsCarrierBrand = 'chrono13localhomepickup';

export function getCarrierBrand(carrierName: string) {
  const carrierBrand = carrierName
    ? carrierName.toLowerCase()
    : 'ExternalCarrier';

  if (carrierBrand === upsCarrierBrand) {
    return 'ups';
  }
  if (carrierBrand.includes('chrono')) {
    return 'chrono';
  }
  if (carrierBrand.includes('colissimo')) {
    return 'colissimo';
  }
  if (
    carrierBrand.includes('ups') &&
    !carrierBrand.includes('pickupstoredelivery')
  ) {
    return 'ups';
  }
  if (carrierBrand.includes('stuart')) {
    return 'stuart';
  }

  if (carrierBrand.includes('mondialrelay')) {
    return 'MondialRelay';
  }

  return 'ExternalCarrier';
}

export function getCarrierCompany(carrierName = '') {
  const carrierCompany = carrierName
    ? carrierName.toLowerCase()
    : 'ExternalCarrier';

  if (
    carrierCompany === upsCarrierBrand ||
    (carrierCompany.includes('ups') &&
      !carrierCompany.includes('pickupstoredelivery'))
  ) {
    return 'UPS';
  }

  if (carrierCompany.includes('chrono')) {
    return 'Chronopost';
  }

  if (carrierCompany.includes('colissimo')) {
    return 'Colissimo';
  }

  if (carrierCompany.includes('mondialrelay')) {
    return 'MondialRelay';
  }

  return 'ExternalCarrier';
}
