import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { intl } from '@savgroup-front-common/core/src/helpers';
import { AuthConfiguration } from 'myaccount/configuration';
import { ClientInformation } from 'myaccount/types';

const GetClientInformation = 'getClientInformation';

interface UseGetClientInformationReturnValue {
  isLoading: boolean;
  clientInformation?: ClientInformation;
}

const useGetClientInformation = (): UseGetClientInformationReturnValue => {
  const { clientId } = AuthConfiguration;

  const { data, isLoading } = useQuery(
    [GetClientInformation, { clientId }],
    async () => {
      if (!clientId) {
        return undefined;
      }

      const response = await fetch(
        `${AuthConfiguration.authority}/api/client/information?clientId=${clientId}`,
        {
          headers: intl?.locale
            ? {
                'accept-language': intl?.locale,
              }
            : {
                'accept-language': 'en',
              },
        },
      );

      const data = await response.json();

      if (data?.failure) {
        logError(
          `Got an empty getClientInformation for clientId: ${clientId}.`,
        );
      }

      return data?.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return { isLoading, clientInformation: data };
};

export default useGetClientInformation;
